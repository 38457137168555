import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Image,
  Stack,
} from "@chakra-ui/react";
import React from "react";

const CustomProductPage = () => {
  const images = [
    { source: require("../assets/cosmetic1.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic1.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic1.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic1.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic2.png") },
    { source: require("../assets/cosmetic2.png") },
  ];
  return (
    <>
      <Stack>
        <Center>
          <Heading size="sm">This is custom page</Heading>
        </Center>
        <Stack overflowY="scroll" maxWidth={"100%"}>
          <Heading size="xs">1. Pick Packaging </Heading>
          {images && (
            <HStack>
              {images.map((image, i) => (
                <Stack
                  key={i}
                  borderRadius="lg"
                  borderWidth={2}
                  borderColor="gray.200"
                >
                  <Image src={image.source} />
                </Stack>
              ))}
            </HStack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default CustomProductPage;
