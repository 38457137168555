import { useToast } from "@chakra-ui/react";
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db, fetchToken } from "../Config/firebase";
import AuthContext from "./hooks/AuthContext";
import store from "store";

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [tokenId, setTokenId] = useState("");
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [courses, setCourses] = useState([]);
  const [productList, setProductList] = useState([]);
  const [cartList, setCartList] = useState();
  const [userData, setUserData] = useState({});
  const [funnel, setFunnel] = useState(true)

  const navigate = useNavigate();
  const toast = useToast();

  const login = (email, password) => {
    setLoading(true);
    return signInWithEmailAndPassword(auth, email, password)
      .then(async (response) => {
        try {
          const docRef = doc(db, "users", response.user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            store.set("user_data", docSnap.data());
            await updateDoc(docRef, { tokenId: arrayUnion(tokenId) });
            // console.log('test')
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.log(error, "ini error");
        }

        toast({
          title: "Success Login",
          // description: `Success Login account ${response.user.displayName} `,
          status: "success",
          duration: 10000,
          isClosable: true,
          position: "top-right",
        });

        setLoading(false);
        return navigate("/");
      })
      .catch((error) => {
        setLoading(false);

        toast({
          title: "Something wrong !",
          description: error.message,
          status: "error",
          duration: 10000,
          isClosable: true,
          position: "top-right",
        });
      });
  };

  const signOut = () => {
    setCartList();
    return auth.signOut();
  };

  //onsnapshot product
  const getProductBisnis = async () => {
    try {
      const q = query(collection(db, "products"), orderBy("nama"), limit(10));
      onSnapshot(q, (querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          const objData = doc.data();
          objData.id = doc.id;
          arr.push(objData);
        });
        setProducts(arr);
        console.log(arr, "this is products");
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  //onsnapshot product
  const getProductName = async (name) => {
    try {
      try {
        onSnapshot(doc(db, "products", name), (doc) => {
          setProductList(doc.data());
        });
      } catch (error) {
        console.log(error.message);
      }
    } catch (error) {
      console.log(error.message);
    }
    return productList;
  };

  //onsnapshot cart
  const getCart = async (user) => {
    //  console.log(user,'in auth context')
    try {
      try {
        onSnapshot(doc(db, "cart", user.uid), (doc) => {
          setCartList(doc.data());
        });
      } catch (error) {
        console.log(error.message);
      }
    } catch (error) {
      console.log(error.message);
    }
    return cartList;
  };

  //onSnapshot course
  const getCourses = async () => {
    try {
      const q = query(collection(db, "courses"), orderBy("name"), limit(10));
      onSnapshot(q, (querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          const objData = doc.data();
          objData.id = doc.id;
          console.log(objData);
          arr.push(objData);
        });
        setCourses(arr);
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const getUserStorage = () => {
    return store.get("user_data");
  };

  const signUp = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const getUser = () => {
    return auth.currentUser;
  };

  const notify = (title, description, status) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 10000,
      isClosable: true,
      position: "top",
    });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
      fetchToken(setTokenId);
      getProductBisnis();
      getCourses();
      getCart(user);
    });
  }, []);

  const value = {
    currentUser,
    tokenId,
    getUser,
    login,
    signOut,
    signUp,
    products,
    courses,
    notify,
    cartList,
    getUserStorage,
    getProductName,
    getCourses,
    funnel,
    setFunnel
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
