import { Box, Button, HStack, Image, Input, InputGroup, InputLeftAddon, Spacer, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { MdEmail, MdVpnKey } from 'react-icons/md'
import AuthContext from '../../Routes/hooks/AuthContext'
import { useNavigate } from 'react-router-dom'
import colors from '../../Utils/colors'
import tulisanpng from '../../assets/tulisanbg.png'

function LoginEmail() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const { login } = useContext(AuthContext)
    const toast = useToast()
    const navigate = useNavigate()

    const handleLogin = async () => {
        if (email !== '' && password !== '') {
          login(email, password)
        } else {
          toast({
            title: 'Brainology.',
            message: 'Cek kembali email dan password anda.',
            color: 'warning'
          })
        }
      }
    

    return (
            <Stack alignItems={'center'} justifyContent='center' h='100vh' bgColor={colors.black}>
                <Stack justifyContent='center' alignItems='center' position={'absolute'}  spacing={3} pb={10}>

                    <Box shadow={5}>
                        <Image
                            shadow={5}
                            w='200px'
                            borderRadius={20}
                            src={tulisanpng}
                            alt="Alternate Text"
                        />
                    </Box>
                    <Spacer />
                    <Stack alignItems="center">
                        <InputGroup w={{
                            base: '100%',
                            md: '285'
                        }}>
                            <InputLeftAddon shadow={3} children={<MdEmail name="email" size={24} color="black" />} />
                            <Input w={{
                                base: '100%',
                                md: '100%'
                            }} placeholder="email"
                            fontSize={'md'}
                                shadow={3}
                                bgColor={'white'}
                                color={"black"}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </InputGroup>
                    </Stack>
                    <Stack alignItems="center">
                        <InputGroup w={{
                            base: '100%',
                            md: '100%'
                        }}>
                            <InputLeftAddon shadow={3} children={<MdVpnKey  size={24} color="black" />} />
                            <Input w={{
                                base: '100%',
                                md: '100%'
                            }} placeholder="password"
                            fontSize={'md'}
                                shadow={3}
                                type="password"
                                bgColor={'white'}
                                color="black"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </InputGroup>
                    </Stack>

                    <Button shadow={3}  size={'sm'} onClick={() => handleLogin()}  >
                        <Text color="black" fontWeight="bold">
                            CONTINUE
                        </Text>
                    </Button>

                    <Spacer />

                    <HStack space={1}>
                        <Text color={'gray.600'} shadow={3}>Don't have any account ?</Text>
                        <Text color={'gray.600'} shadow={3} cursor='pointer' fontWeight='bold' onClick={() => navigate("/signup")}>Join now !</Text>
                    </HStack>
                </Stack>
            </Stack>
    )
}

export default LoginEmail