import { Badge, Box, Center, Icon, Image, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { BiBook, BiBookOpen, BiBrain } from "react-icons/bi";
import { FaBook, FaBrain } from "react-icons/fa";
import {
  IoAppsOutline,
  IoCartOutline,
  IoHeartOutline,
  IoHomeOutline,
  IoPersonOutline,
  IoBookOutline,
  IoCreate,
} from "react-icons/io5";
import { MdFiberSmartRecord } from "react-icons/md";
import { Link } from "react-router-dom";
import AuthContext from "../Routes/hooks/AuthContext";
import image from '../assets/lambangkopong.png'
import colors from "../Utils/colors";

function AppFooter() {
  // const [cartData,setCartData]=useState
  const { cartList } = useContext(AuthContext);

  // console.log(cartList,'ini di halaman appfooter')
  const getCartData = () => {
    // console.log(cartList)
    // setCartData(cartList)
  };

  useEffect(() => {
    getCartData();

    return () => { };
  }, []);

  return (
    <Box
      bgColor="black"
      shadow="base"
      position="sticky"
      bottom={0}
      p="3"
      mt={0}
    >
      <SimpleGrid columns={5} textAlign="center" fontSize="sm" color="white">
        <Box>
          <Link to="/">
            <Icon as={IoHomeOutline} boxSize={25} />
            <Text fontSize={12}>Home</Text>
          </Link>
        </Box>
        <Box>
          <Link to="/academy">
            <Icon as={IoBookOutline} boxSize={25} />
            <Text fontSize={12}>Academy</Text>
          </Link>
        </Box>
        <Stack
          width="16"
          height="16"
          p="2"
          borderRadius="full"
          mt="-9"
          bgColor={colors.darklight}
          color="black"
          shadow="lg"
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Link to="/product">
            <Image src={image} borderRadius={"md"} />
          </Link>
        </Stack>
        <Box>
          <Link to="/cart">
            <Icon as={IoCartOutline} boxSize={25} />
            <Badge
              bgColor="red"
              color="white"
              mt="-10"
              ml="-2"
              borderRadius="full"
            >
              {cartList?.products ? cartList.products.length : 0}
            </Badge>
            <Text fontSize={12}>Cart</Text>
          </Link>
        </Box>

        <Box>
          <Link to="/profile">
            <Icon as={IoPersonOutline} boxSize={25} />
            <Text fontSize={12}>Profile</Text>
          </Link>
        </Box>
      </SimpleGrid>
    </Box>
  );
}

export default AppFooter;
