import { ArrowLeftIcon } from "@chakra-ui/icons";
import {
  AspectRatio,
  Badge,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { arrayUnion, doc, getDoc, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { db } from "../Config/firebase";

import AuthContext from "../Routes/hooks/AuthContext";
import colors from "../Utils/colors";

function ProductSinglePage() {
  const [product, setProduct] = useState();
  const param = useParams();
  const navigate = useNavigate();

  const { currentUser, notify } = useContext(AuthContext);

  const getProduct = async () => {
    try {
      const docRef = doc(db, "products", param.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setProduct(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getYoutubeID = (data) => {
    const id = data.split("/");
    return `https://www.youtube.com/embed/${id[id.length - 1]}`;
  };

  const handleBuy = async () => {
    //check if user loggedin ?
    console.log(currentUser, "this is the current user");
    if (currentUser) {
      await setDoc(
        doc(db, "cart", currentUser.uid),
        {
          products: arrayUnion({
            id: param.id,
            name: product.nama,
            price: product.price,
            image: product.thumbnail,
            qty: 1,
          }),
        },
        { merge: true }
      );
      notify(
        "Keranjang",
        "Berhasil menambahkan produk ke keranjang kamu",
        "success"
      );
    } else {
      notify(
        "Daftar / Login",
        "Kamu harus mendaftarkan diri untuk membeli produk ini",
        "warning"
      );
      navigate("/login/phone", { replace: true });
    }
  };

  useEffect(() => {
    getProduct();

    return () => {};
  }, []);

  return (
    <>
      <Box mt={4}>
        <Spacer />
        <HStack justifyContent={"center"}>
          <Link to="/product">
            <ArrowLeftIcon />
          </Link>
          {/* <Spacer /> */}
          {product ? (
            <Heading
              fontWeight={600}
              size="lg"
              p="1"
              align={"center"}
              color={colors.white}
            >
              {product.nama}
            </Heading>
          ) : (
            <></>
          )}
        </HStack>
        <HStack m="1" justifyContent={"center"}>
          {product ? (
            product.tags.map((x, i) => (
              <Badge key={i} bgColor="red.600" color="white" fontSize="0.5em">
                {x}
              </Badge>
            ))
          ) : (
            <></>
          )}
        </HStack>
      </Box>

      <Spacer />

      <AspectRatio width="100%" ratio={16 / 9} my={4}>
        <iframe
          title="naruto"
          src={product?.preview !== undefined ? product?.preview : "https"}
          allowFullScreen
        />
      </AspectRatio>
      <Container>
        <Text
          p="2"
          my={3}
          fontSize={12}
          textAlign="justify"
          color={colors.white}
        >
          {product ? (
            <div dangerouslySetInnerHTML={{ __html: product.description }} />
          ) : (
            <></>
          )}
        </Text>
      </Container>

      <Container>
        <Button
          bg={colors.theme}
          width="full"
          p="2"
          // onClick={() => handleBuy()}
          onClick={() =>
            window.open(
              `https://wa.me/+6285891471869?text=Hai saya mau memesan buku ${product.nama}!`
            )
          }
        >
          Beli
        </Button>
      </Container>
    </>
  );
}

export default ProductSinglePage;
