import { ArrowLeftIcon } from "@chakra-ui/icons";
import {
  AspectRatio,
  Badge,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  Stack,
  Icon,
  Text,
  Divider,
  Image,
  useToast,
} from "@chakra-ui/react";
import { MdCheckCircle, MdSettings } from "react-icons/md";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import YouTube from "react-youtube";
import { auth, db } from "../Config/firebase";
import { AiOutlinePlayCircle } from "react-icons/ai";
import AuthContext from "../Routes/hooks/AuthContext";
import store from "store";
import ReactAudioPlayer from "react-audio-player";
import colors from "../Utils/colors";

function CourseSinglePage() {
  const [course, setCourse] = useState();
  const [dataAudio, setDataAudio] = useState();
  const [preview, setPreview] = useState(null);
  const [embedId, setEmbedId] = useState({});
  const [users, setUsers] = useState();

  const toast = useToast();

  const param = useParams();
  const navigate = useNavigate();
  const user_data = store.get("user_data");
  const { currentUser, notify } = useContext(AuthContext);

  console.log(user_data.courses, 'ini user Data ')

  const getProduct = async () => {
    try {
      const docRef = doc(db, "courses", param.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        setCourse(docSnap.data());
        console.log('test')
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error.message);
    }
  };



  console.log(user_data, "ini currentuser");
  const handlePreview = (item, index) => {
    // console.log("item: ", item);
    // if (
    //   auth.currentUser &&
    //   user_data &&
    //   user_data?.courses?.includes(course?.keyword)
    // ) {
    //   setPreview(item);
    //   setEmbedId(item.link.split(".be/")[1]);
    // } else {
    //   console.log(currentUser, "ini item");
    //   notify(
    //     "Maaf",
    //     "Kamu belum mempunyai produk ini. Tambahkan ke keranjang dan lakukan pembayaran ya!",
    //     "warning"
    //   );

    // }

    if (course.type === 'video') {
      if (
        auth.currentUser &&
        user_data &&
        user_data?.courses?.includes(course?.keyword)
      ) {
        setPreview(item);
        setEmbedId(item.link.split(".be/")[1]);
      } else {
        console.log(currentUser, "ini item");
        notify(
          "Maaf",
          "Kamu belum mempunyai produk ini. Tambahkan ke keranjang dan lakukan pembayaran ya!",
          "warning"
        );

      }
    }

    if (course.type === 'audiobook') {
      if (
        auth.currentUser &&
        user_data &&
        user_data?.courses?.includes(course?.keyword)
      ) {
        setPreview(item);
        // setEmbedId(item.link.split(".be/")[1]);
      } else {
        console.log(currentUser, "ini item");
        notify(
          "Maaf",
          "Kamu belum mempunyai produk ini. Tambahkan ke keranjang dan lakukan pembayaran ya!",
          "warning"
        );

      }
    }

    if (course.type === 'audio') {
      if (
        auth.currentUser &&
        user_data &&
        user_data?.courses?.includes(item?.keyword)
      ) {
        setPreview(item);
        // setEmbedId(item.link.split(".be/")[1]);
      } else {
        console.log(currentUser, "ini item");
        notify(
          "Maaf",
          "Kamu belum mempunyai produk ini. Tambahkan ke keranjang dan lakukan pembayaran ya!",
          "warning"
        );

      }
    }
  };

  const handleBuy = async () => {
    //check if user loggedin ?
    if (currentUser) {
      console.log({
        id: param.id,
        name: course.name,
        price: course.price,
        image: course.thumbnail,
        qty: 1,
      }, 'test')
      await setDoc(
        doc(db, "cart", currentUser.uid),
        {
          products: arrayUnion({
            id: param.id,
            name: course.name,
            price: course.price,
            image: course.thumbnail,
            qty: 1,
          }),
        },
        { merge: true }
      );
      notify(
        "Keranjang",
        "Berhasil menambahkan produk ke keranjang kamu",
        "success"
      );
    } else {
      notify(
        "Daftar / Login",
        "Kamu harus mendaftarkan diri untuk membeli produk ini",
        "warning"
      );
      navigate("/login/phone", { replace: true });
    }
  };

  const handleBuyAudio = async (audios) => {
    //check if user loggedin ?
    const params = {
      id: param.id,
      name: audios.name,
      price: audios.price,
      image: audios.thumbnail_item,
      link: audios.link,
      qty: 1,
      productType: "audio",
    }
    console.log(params, 'ini params')
    if (currentUser) {
      await setDoc(
        doc(db, "cart", currentUser.uid),
        {
          products: arrayUnion(params),
        },
        { merge: true }
      );
      notify(
        "Keranjang",
        "Berhasil menambahkan produk ke keranjang kamu",
        "success"
      );
    } else {
      notify(
        "Daftar / Login",
        "Kamu harus mendaftarkan diri untuk membeli produk ini",
        "warning"
      );
      navigate("/login/phone", { replace: true });
    }
  };

  useEffect(() => {
    getProduct();
    return () => { };
  }, []);

  return (
    <>
      <HStack justifyContent={"space-between"} py={3} px={2}>
        <Link to="/academy">
          <ArrowLeftIcon color={colors.white} />
        </Link>
      </HStack>

      <Stack justifyContent={"center"} spacing={3} p={3}>
        {course ? (
          <Heading align={"center"} size={"md"} color={colors.white}>
            {course?.name}
          </Heading>
        ) : (
          <></>
        )}
        <HStack justifyContent={"center"}>
          {course ? (
            course?.tags.map((x, i) => (
              <Badge key={i} bgColor="red" color="white">
                {x}
              </Badge>
            ))
          ) : (
            <></>
          )}
        </HStack>
      </Stack>

      {course?.type === "video" ? (
        <Box my={5}>
          <AspectRatio width="100%" ratio={16 / 9} mb="1">
            <iframe
              title={preview == null ? course?.content[1].name : preview.name}
              src={
                preview == null
                  ? "https://www.youtube.com/embed/VQjEB0rIIK0?rel=0&autoplay=1&mute=1"
                  : preview?.link
              }
              allowFullScreen
            />
          </AspectRatio>
        </Box>
      ) : course?.type === "audio" ? (
        <>
          <Container display="flex" flexDirection="row" justifyContent="center">
            <ReactAudioPlayer
              src={preview == null ? course?.content[0]?.link : preview?.link}
              autoPlay
              controls
              style={{ width: "full" }}
            />
          </Container>
        </>
      ) : 
      
        course?.type === "audiobook" ? (
          <>
          <Stack alignItems={'center'} justifyContent="center" spacing={5}>
            <Stack w={'200px'}>
              <Image src={preview == null ? course?.content[0]?.thumbnail_item : preview?.thumbnail_item}/>
            </Stack>
            <Stack w={'400px'}>
              <Text color={'white'} fontSize='sm' fontWeight={'bold'}>{preview == null ? course?.content[0]?.name : preview?.name}</Text>
            </Stack>
            <Stack w={'400px'}>
              <Text color={'white'} fontSize='xs'>{preview == null ? course?.content[0]?.description : preview?.description}</Text>
            </Stack>
            <ReactAudioPlayer
              src={preview == null ? course?.content[0]?.link : preview?.link}
              autoPlay
              controls
              style={{ width: "full" }}
            />
          </Stack>
        </>
        ) :
      null}





      {course?.type === 'video' ? (
        auth.currentUser &&
          user_data &&
          user_data?.courses?.includes(course?.keyword) ? (
          null
        ) : (
          <Container>
            <Button
              bg={colors.theme}
              width="full"
              p="2"
              onClick={() => handleBuy()}
              my={5}
            >
              Tambah ke Keranjang
            </Button>
            {/* <Text>{JSON.stringify(user_data)}</Text> */}
          </Container>
        )
      ) : 

      course?.type === 'audiobook' ? (
        auth.currentUser &&
          user_data &&
          user_data?.courses?.includes(course?.keyword) ? (
          null
        ) : (
          <Container>
            <Button
              bg={colors.theme}
              width="full"
              p="2"
              onClick={() => handleBuy()}
              my={5}
            >
              Tambah ke Keranjang
            </Button>
            {/* <Text>{JSON.stringify(user_data)}</Text> */}
          </Container>
        )
      ) : 
      
      
      (null)}



      <Box
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Text px={5} fontWeight="bold">
          Klik {course?.type} di bawah ini!
        </Text>
        <List spacing={3} px={5}>
          {course?.type === "video"
            ? course?.content.map((item, i) => (
              <>

                <HStack
                  w="full"
                  _hover={{
                    bg: colors.semidark,
                    borderRadius: "md",
                  }}
                  key={i}
                >
                  <Text color={colors.white}>{i + 1}. </Text>
                  <ListItem
                    py={2}
                    key={i}
                    cursor="pointer"
                    onClick={() => handlePreview(item, i)}
                    color={colors.white}
                  >
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <strong>{item.name}</strong>
                    {item.link === preview?.link ? (
                      <Icon
                        as={AiOutlinePlayCircle}
                        color="green.500"
                        mx={4}
                      />
                    ) : null}
                  </ListItem>
                </HStack>
              </>
            ))

            : course?.type === "audio"
              ? course?.content.map((item, i) => (
                <>

                  <HStack
                    w="full"
                    _hover={{ bg: colors.semidark }}
                    p={2}
                    key={i}
                    justifyContent={"space-between"}
                  >
                    <HStack>
                      <Text color={colors.white}>{i + 1}. </Text>
                      <ListItem
                        py={2}
                        key={i}
                        cursor="pointer"
                        color={colors.white}
                        onClick={() => handlePreview(item, i)}
                      >
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        <strong>{item.name}</strong>
                        {item.link === preview?.link ? (
                          <Icon
                            as={AiOutlinePlayCircle}
                            color="green.500"
                            mx={4}
                          />
                        ) : null}
                      </ListItem>
                    </HStack>
                    {user_data?.courses?.includes(item?.keyword) ? (
                      <Button
                        size={"sm"}
                        bg={colors.theme}
                        onClick={() => handlePreview(item, i)}
                      >
                        Play
                      </Button>
                    ) : (
                      <Button
                        size={"sm"}
                        bg={'green.300'}
                        onClick={() => handleBuyAudio(item)}
                      >
                        Buy Course
                      </Button>
                    )}
                  </HStack>
                </>
              ))
              : 
              course?.type === "audiobook" ? course?.content.map((item, i) => { 
                return(
                    <>

                <HStack
                  w="full"
                  _hover={{
                    bg: colors.semidark,
                    borderRadius: "md",
                  }}
                  key={i}
                >
                  <Text color={colors.white}>{i + 1}. </Text>
                  <ListItem
                    py={2}
                    key={i}
                    cursor="pointer"
                    onClick={() => handlePreview(item, i)}
                    color={colors.white}
                  >
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <strong>{item.name}</strong>
                    {item.link === preview?.link ? (
                      <Icon
                        as={AiOutlinePlayCircle}
                        color="green.500"
                        mx={4}
                      />
                    ) : null}
                  </ListItem>
                </HStack>
              </>
              )})
              : null}
        </List>
      </Box>
    </>
  );
}

export default CourseSinglePage;
