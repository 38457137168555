import {
  Badge,
  Box,
  Button,
  HStack,
  Image,
  Icon,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../Routes/hooks/AuthContext";
import store from "store";
import {
  IoAppsOutline,
  IoCartOutline,
  IoHeartOutline,
  IoHomeOutline,
  IoPersonOutline,
  IoBookOutline,
} from "react-icons/io5";

function AppHeader() {
  const { currentUser, signOut, cartList } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleLogOut = () => {
    signOut()
      .then(() => {
        navigate("/", { replace: true });
        store.clearAll();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <HStack bgColor="brand.100" p="2" width="full" mb={-2}>
        <Image
          height="40px"
          src="https://assets.cdn.msgsndr.com/4b0svr3REvejq11aC2Xn/media/92bae1ce-86c0-475e-bc10-7f913192ab15.png"
        />
        <Spacer />
        {currentUser?.uid ? (
          <>
            {/* <Box>
              <Link to="/cart">
                <Icon as={IoCartOutline} boxSize={5} color="gray.50" />
                <Badge
                  bgColor="red"
                  color="white"
                  mt="-8"
                  ml="-2"
                  borderRadius="full"
                >
                  {cartList?.products ? cartList.products.length : 0}
                </Badge>
              </Link>
            </Box> */}
            <Button
              onClick={() => handleLogOut()}
              colorScheme="red"
              variant="solid"
              size="sm"
            >
              Logout
            </Button>
          </>
        ) : (
          <Link to="/login/phone">
            <Button bgColor="yellow" variant="outline" size="sm">
              Login
            </Button>
          </Link>
        )}
      </HStack>
    </>
  );
}

export default AppHeader;
