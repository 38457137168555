import {
  AspectRatio,
  Badge,
  Box,
  Divider,
  HStack,
  Image,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../Config/firebase";
import colors from "../Utils/colors";

const BookSinglePage = () => {
  const [book, setBook] = useState([]);
  const params = useParams();

  const getBookData = async () => {
    const docRef = doc(db, "bookOfWeek", params.id);
    const docSnap = await getDoc(docRef);

    try {
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setBook(docSnap.data());
      } else {
      }
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  useEffect(() => {
    getBookData();

    return () => {};
  }, []);

  return (
    <Box overflowY={"auto"} bg={colors.darkblue}>
      {book ? (
        <Box>
          <AspectRatio
            maxW={500}
            ratio={3 / 2}
            // objectFit={"cover"}
          >
            <Image src={book?.image} borderRadius={"md"} />
          </AspectRatio>

          <Box align={"center"} mt={5} color={"white"}>
            <Text fontWeight={"bold"} textTransform={"uppercase"}>
              {book?.title}
            </Text>
            <Text fontWeight={"semibold"} fontSize={14}>
              {book?.penulis}
            </Text>
            <HStack justifyContent={"center"} my={2}>
              {/* <Badge colorScheme={"yellow"}>{book?.tags[0]}</Badge>
              <Badge colorScheme={"yellow"}>{book?.tags[1]}</Badge> */}
            </HStack>
          </Box>

          <Box mt={5} px={5} fontSize={14} color={"white"}>
            <Text>Sinopsis Buku</Text>
            <Divider my={2} />
            <Text mb={4} color={colors.white}>
              {book?.synopsis}
            </Text>
          </Box>
        </Box>
      ) : (
        <>
          <Spinner />
        </>
      )}
    </Box>
  );
};

export default BookSinglePage;
