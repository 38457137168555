import {
  Button,
  Container,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  IconButton,
  Td,
  Avatar,
  Stack,
  Box,
  Tag,
  Select,
  Table,
  TableContainer,
  Th,
  Tbody,
  Tr,
  Thead,
  useToast,
  Spacer,
} from "@chakra-ui/react";
import { deleteUser } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { BiSort } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import { db } from "../../Config/firebase";

const AdminRegistration = () => {
  const toast = useToast();
  const [editDataUser, setEditDataUser] = useState(null);
  const [userData, setUserData] = useState([]);
  const [filter, setFilter] = useState("");
  const [dataFilter, setDataFilter] = useState([]);
  const [dataSort, setDataSort] = useState(dataFilter);
  const [formData, setFormData] = useState({
    name: "",
    paid_date: "",
    phone: "",
    email: "",
    address: "",
    products: "",
    courses: "",
  });
  const [titleTable, setTitleTable] = useState([
    { title: "index", filter: false },
    { title: "Name", filter: false },
    { title: "User ID", filter: false },
    { title: "Email", filter: false },
    { title: "Phone", filter: false },
    { title: "Address", filter: false },
    { title: "Products", filter: false },
    { title: "Courses", filter: false },
    { title: "Actions", filter: false },
  ]);
  const [registerNew, setRegisterNew] = useState(false);

  const getDataUser = async () => {
    try {
      const ref = collection(db, "users");
      const q = query(
        ref
        // ,orderBy("update", "desc")
      );
      let result = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        result.push(doc.data());
      });
      console.log("result", result);
      setUserData(result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const editDataComponent = async (id) => {
    console.log(id);
    const docRef = doc(db, "users", id);
    const docSnap = await getDoc(docRef);
    let data = {};
    if (docSnap.exists()) {
      data = docSnap.data();
      setEditDataUser(data);
    } else {
      console.log("No such document!");
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "users", id));
      toast({
        title: "Data Deleted",
        description: "",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      getDataUser();
      deleteUser(id);
    } catch (error) {
      console.log(error);
    }
    getDataUser();
  };

  useEffect(() => {
    getDataUser();

    return () => {
      setUserData([]);
    };
  }, []);

  const getData = () => {
    const dataArr = userData;
    console.log(filter, "filter");
    const filterData = dataArr.filter((x) => x.role === filter);
    if (filter === "") {
      setDataFilter(dataArr);
      console.log("gaada");
    } else {
      setDataFilter(filterData);
      console.log("ada");
    }
  };

  useEffect(() => {
    getData();

    return () => {
      setDataFilter([]);
    };
  }, [filter]);

  return (
    <>
      <Box overflowY="scroll">
        <Button
          bg="green.500"
          color="white"
          width="20rem"
          m={5}
          py={2}
          onClick={() => setRegisterNew(!registerNew)}
        >
          Register a Member +
        </Button>

        {registerNew ? (
          <Container maxW="90%">
            <FormControl>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                id="name"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="paid_date">Paid Date</FormLabel>
              <Input
                id="paid_date"
                name="paid_date"
                placeholder="Enter the date of payment"
                value={formData.paid_date}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="phone">Phone</FormLabel>
              <Input
                id="phone"
                name="phone"
                placeholder="Enter your phone number"
                value={formData.phone}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="address">Address</FormLabel>
              <Input
                id="address"
                name="address"
                placeholder="Enter your address"
                value={formData.address}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="products">Products</FormLabel>
              <Select
                id="products"
                name="products"
                placeholder="Select a product"
                value={formData.products}
                onChange={handleChange}
              >
                <option value="">Select a product</option>
                <option value="product1">Product 1</option>
                <option value="product2">Product 2</option>
                <option value="product3">Product 3</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="courses">Courses</FormLabel>
              <Select
                id="courses"
                name="courses"
                placeholder="Select a course"
                value={formData.courses}
                onChange={handleChange}
              >
                <option value="">Select a product</option>
                <option value="product1">Product 1</option>
                <option value="product2">Product 2</option>
                <option value="product3">Product 3</option>
              </Select>
            </FormControl>
            <Button
              bg="green"
              color="white"
              m={5}
              w="full"
              onClick={() => console.log(formData)}
            >
              Register a Member +
            </Button>
          </Container>
        ) : null}

        <TableContainer>
          <Table variant="simple">
            <Thead bgColor="teal" position="sticky" top={22} zIndex={2}>
              <Tr>
                {titleTable.map((x, i) => (
                  <Th key={i}>
                    <Button
                      key="kuda1"
                      variant="ghost"
                      // onClick={() => sortBy(x.title)}
                      onClick={() => console.log(x.title)}
                    >
                      <HStack spacing="1">
                        <Text>{x.title}</Text>
                        <BiSort fontWeight="bold" />
                      </HStack>
                    </Button>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {userData ? (
                userData?.map((x, index) => (
                  <Tr cursor="pointer">
                    <Td>
                      <Text align="center">{++index}</Text>
                    </Td>
                    <Td>
                      <HStack spacing={3}>
                        <Box>
                          <Avatar
                            size="md"
                            name={x.photoProfile}
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Agnez_Mo_2019_by_Glenn_Francis.jpg/440px-Agnez_Mo_2019_by_Glenn_Francis.jpg"
                          />
                        </Box>
                        <Stack spacing={2}>
                          <Text>{x.name}</Text>
                          <Box>
                            {x.role === "admin" ? (
                              <Tag bgColor="yellow" color="black">
                                Admin
                              </Tag>
                            ) : x.role === "member" ? (
                              <Tag bgColor="green">Member</Tag>
                            ) : x.role === "user" ? (
                              <Tag bgColor="red">User</Tag>
                            ) : (
                              <Tag bgColor="red">User</Tag>
                            )}
                          </Box>
                          <Text size="md">
                            Exp :{" "}
                            {x.expired_user &&
                              new Date(x.expired_user).toLocaleString()}
                          </Text>
                        </Stack>
                      </HStack>
                    </Td>
                    <Td>
                      <Stack>
                        <Text>{x.uid_user}</Text>
                        <Text>
                          {/* {moment(x.update.seconds * 1000).toLocaleString()} */}
                          expiry date here
                        </Text>
                        <Text textTransform={"capitalize"}>
                          Courses :{" "}
                          {x.courses
                            ? `${x.courses.length}`
                            : "Belum Terdaftar"}
                        </Text>
                      </Stack>
                    </Td>
                    <Td>
                      <Stack spacing={2}>
                        <Box>{x.email}</Box>
                        <Box>city here</Box>
                      </Stack>
                    </Td>
                    <Td>{x.nohp}</Td>
                    <Td>{x.address}</Td>
                    <Td>{x.courses}</Td>
                    <Td>{x.products}</Td>
                    <Td>
                      {/* <a href={`/watch/${x.video}`} target="_blank" rel="noreferer noreferrer">
                      <IconButton m="2" aria-label="Search database" colorScheme="green" icon={<SearchIcon />} />
                    </a> */}
                      <IconButton
                        // onClick={() => editDataComponent(x.uid_user)}
                        m="2"
                        aria-label="Edit database"
                        colorScheme="yellow"
                        icon={<EditIcon />}
                      />
                      <IconButton
                        // onClick={() => handleDelete(x.uid_user)}
                        m="2"
                        aria-label="Delete database"
                        colorScheme="red"
                        icon={<DeleteIcon />}
                      />
                    </Td>
                  </Tr>
                ))
              ) : (
                <></>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default AdminRegistration;
