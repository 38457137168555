import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  Radio,
  RadioGroup,
  Stack,
  Flex,
  Center,
  Spinner,
  Divider,
} from "@chakra-ui/react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
  AiOutlineDelete,
} from "react-icons/ai";
import { collection, query, where, getDocs } from "firebase/firestore";
import { orderBy } from "lodash";
import { db } from "../../Config/firebase";
import AuthContext from "../../Routes/hooks/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { midtransPayment } from "../../Api/payment";
import { AiOutlineCopy } from "react-icons/ai";
import colors from "../../Utils/colors";

function CheckoutPage() {
  var store = require("store");
  const user_data = store.get("user_data");
  const [dataOrder, setDataOrder] = useState();
  const { currentUser } = useContext(AuthContext);
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [response, setResponse] = useState(null);
  const [bank, setBank] = useState();

  console.log(state.products.products, "state.producst.products nih");
  const [products, setProducts] = useState(state.products.products);

  const getOrder = async () => {
    try {
      const q = query(
        collection(db, "orders"),
        where("user_uid", "==", currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      let data = [];
      querySnapshot.forEach((doc) => {
        let newData = doc.data();
        newData.id = doc.id;
        data.push(newData);
      });
      setDataOrder(data);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePayment = async () => {
    setLoading(true);
    const res = await midtransPayment(
      state.products,
      state.totalPrice,
      currentUser.uid,
      bank,
      user_data.name
    );
    console.log(res.data, "ini di handle payment");

    setResponse(res.data);
    setLoading(false);
  };


  const width = window.innerWidth
  const height = window.innerHeight

  useEffect(() => {
    // getOrder();

    return () => {};
  }, []);

  

  return (
    <>
      {/* <Heading>checkout : </Heading>
       */}
      <Stack bg={colors.darkblue} h="full" minH={height}>
        <Heading
          mt={10}
          fontSize={20}
          fontWeight={500}
          textAlign="center"
          color="gray.100"
        >
          Checkout
        </Heading>
        <Text fontSize={15} color="gray.100" textAlign="center">
          Periksa kembali pesananmu ya!
        </Text>
        <Center
          justifySelf="center"
          display="flex"
          flexDirection="column"
          overflowY="scroll"
        >
          {products?.product?.length > 0 ? (
            products?.products?.map((product, i) => (
              <>
                <Box key={i} boxShadow="md" w="95%" bg="white" boxRadius="md">
                  <HStack>
                    <Image
                      p="1"
                      src={product.image}
                      width="32"
                      alt={product.name}
                    />
                    <Box>
                      <Text fontSize={11}>{product.name}</Text>
                      <Text fontSize={11} color="gray.600">
                        Rp{" "}
                        {Intl.NumberFormat("en-ID", {
                          maximumSignificantDigits: 3,
                        }).format(product.price)}
                      </Text>
                    </Box>
                    <Spacer />
                  </HStack>
                </Box>
              </>
            ))
          ) : (
            <Flex fontSize="xs" direction="column">
              <Button alignSelf="center" colorScheme="blue" size="sm" mt={10}>
                <Link to="/order">Lihat Order</Link>
              </Button>
            </Flex>
          )}
        </Center>

        <Box
          bg="white"
          padding={5}
          width="md"
          // right={0}
          // left={0}
        >
          {state?.totalPrice > 0 ? (
            <>
              <RadioGroup onChange={setBank} value={bank}>
                <Stack direction="column">
                  <Radio size="sm" value="BRI">
                    BRI Virtual Account
                  </Radio>
                  <Radio size="sm" value="BNI">
                    BNI Virtual Account
                  </Radio>
                  <Radio size="sm" value="MANDIRI">
                    MANDIRI Virtual Account
                  </Radio>
                  <Radio size="sm" value="PERMATA">
                    PERMATA Virtual Account
                  </Radio>
                </Stack>
              </RadioGroup>
              <HStack>
                <Spacer />
                <HStack>
                  <Text>Total</Text>
                  <Heading size="sm">
                    IDR{" "}
                    {state?.totalPrice
                      ? Intl.NumberFormat("en-ID", {
                          maximumSignificantDigits: 3,
                        }).format(state?.totalPrice)
                      : 0}
                  </Heading>
                </HStack>
              </HStack>
              <Divider />
              {response == null ? null : (
                <Stack alignItems="center">
                  <Text fontSize={13}>
                    Type : {response?.data?.type_payment}
                  </Text>
                  <HStack>
                    <Text fontSize={13}>
                      Virtual Account {bank}:{" "}
                      <strong>{response?.data?.account_number}</strong>
                    </Text>

                    <Box
                      padding={2}
                      borderWidth={1}
                      borderColor="tomato"
                      borderRadius={5}
                      _hover={{ bg: "gray.200" }}
                      _active={{ bg: "gray.400" }}
                      cursor="pointer"
                    >
                      <CopyToClipboard
                        text={response?.data?.account_number}
                        onCopy={() => setCopied(true)}
                      >
                        <AiOutlineCopy color="tomato" />
                      </CopyToClipboard>
                    </Box>
                  </HStack>
                  {copied ? (
                    <Text fontSize={10} color="gray.600">
                      VA berhasil dicopy.
                    </Text>
                  ) : null}
                  {JSON.stringify(response.status) === 'false' && (
                    <Text>{JSON.stringify(response.message)}</Text>
                  )}
                </Stack>
              )}

              {bank ? (
                <Box justifyContent="center">
                  <Button
                    m={5}
                    w="90%"
                    colorScheme="blue"
                    onClick={() => handlePayment()}
                    alignSelf="center"
                  >
                    Lanjutkan Pembayaran {loading ? <Spinner mx={4} /> : null}
                  </Button>
                </Box>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </Box>
      </Stack>
    </>
  );
}

export default CheckoutPage;
