import {
  Button,
  Center,
  Container,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { AiOutlineMail } from 'react-icons/ai';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppHeader from "../../Components/AppHeader";
import { auth, db } from "../../Config/firebase";
import AuthContext from "../../Routes/hooks/AuthContext";
import store from "store";

function LoginPage() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [expandForm, setExpandForm] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [loading, setLoading] = useState(false);

  const { notify } = useContext(AuthContext);
  const countryCode = "+62";

  const navigate = useNavigate();

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log(response, "response");
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  };

  const handleEnter = (e, action) => {
    if (e.key == "Enter" && action == "login") {
      setUpRecaptcha();
    } else if (e.key == "Enter" && action == "otp") {
      verifyOtp();
    }
  };

  const verifyOtp = () => {
    setLoading(true);
    if (otpCode.length === 6) {
      setLoading(true);
      // console.log(otpCode)
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otpCode)
        .then(async (result) => {
          const user = result.user;
          console.log(user, "this is the return");

          try {
            const docRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(docRef);
            console.log(docSnap.data(), "this is from uid");
            store.set("user_data", docSnap.data());
            if (!docSnap.exists()) {
              try {
                await setDoc(
                  doc(db, "users", user.uid),
                  { role: "user", nohp: user.phoneNumber },
                  { merge: true }
                );
              } catch (error) {
                console.log("error in creating usercredential:", error.message);
              }
            }
          } catch (error) {
            console.log(
              "error in getting usercredential from users:",
              error.message
            );
          }

          setLoading(false);
          notify(
            "Login Sukses",
            `Login sukses dengan OTP ${otpCode}`,
            "success"
          );
          navigate("/");
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          setLoading(false);
          console.log(error, "error");
          notify("Login gagal", error.message, "error");
        });
    }
    setLoading(false);
  };

  const setUpRecaptcha = () => {
    // get first number & convert with +62
    // const firstDigitNum = Number(phoneNumber);
    // if(firstDigitNum===0)
    // const newNumber

    setExpandForm(true);
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch(function (error) {
        // Error; SMS not sent
        // ...
        console.log(error);
      });
  };

  return (
    <>
      <Center height="90vh">
        <Stack>
          <div id="recaptcha-container"></div>
          <Heading>Login / Daftar</Heading>
          <InputGroup>
            <InputLeftAddon children="+62" />
            <Input
              type="number"
              maxW="2xs"
              placeholder="811223344 (No HP)"
              width="full"
              onChange={(e) => setPhoneNumber(countryCode + e.target.value)}
              onKeyDown={(e) => handleEnter(e, "login")}
            />
          </InputGroup>
          <Button
            colorScheme="yellow"
            id="sign-in-button"
            onClick={() => setUpRecaptcha()}
            disabled={loading}
          >
            Login
          </Button>
          {expandForm && (
            <Stack>
              <Input
                type="text"
                maxW="2xs"
                placeholder="OTP"
                width="full"
                onChange={(e) => setOtpCode(e.target.value)}
                onKeyDown={(e) => handleEnter(e, "otp")}
              />
              <Button onClick={() => verifyOtp()} disabled={loading}>
                Verify OTP {loading ? <Spinner mx={5} /> : null}
              </Button>
            </Stack>
          )}
          <Button
            // colorScheme="yellow"
            size='sm'
            id="sign-in-button"
            onClick={() => navigate('/login/email')}
            disabled={loading}
          >
            <HStack positon='fixed' bottom={20}>
              <AiOutlineMail />
              <Text>Login With Email</Text>
            </HStack>
          </Button>
        </Stack>
      </Center>
        
    </>
  );
}

export default LoginPage;
