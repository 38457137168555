import { Box, Button, Container, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AppFooter from "./Components/AppFooter";
import AppHeader from "./Components/AppHeader";
import AdminRegistration from "./Pages/registration/AdminRegistration";
import AuthContext from "./Routes/hooks/AuthContext";

import MainRoute from "./Routes/MainRoute";
import colors from "./Utils/colors";

function App() {


	const { funnel, setFunnel } = useContext(AuthContext);




  const WebLayout = () => {
    return(
     <Button>test</Button>
    )
  }


  const WebAppLayout = () => {
    return(
      <Container p="0" shadow="2xl" width="md">
      <Stack minH="100vh" bgColor={colors.black}>
        <AppHeader />
        <MainRoute />
        <Spacer />
        <AppFooter />
      </Stack>
    </Container>
    )
  }

  return (
    <>
    { funnel ? (
      <WebAppLayout />
    ) : (
      <WebLayout />
    ) }
    </>
  );
}

export default App;
