import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Spinner,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../Routes/hooks/AuthContext";
import colors from "../Utils/colors";

function AcademyPage() {
  const { courses } = useContext(AuthContext);

  

  return (
    <>
      <Box overflowY="auto">
        <SimpleGrid columns={1} gap="2" mt={2}>
          {courses ? (
            courses.map((x, i) => (
              <Link key={i} to={`/courses/${x.id}`}>
                <Center>
                  <Box
                    shadow="base"
                    p="2"
                    m="1"
                    boxShadow="inner"
                    width="sm"
                    borderRadius={"md"}
                    bg={colors.semidark}
                  >
                    <Center>
                      <Image
                        src={x.thumbnail}
                        width="full"
                        padding="2"
                        borderRadius="md"
                      />
                    </Center>
                    <HStack p="1">
                      <Heading fontSize="md" color={colors.white}>
                        {x.name}
                      </Heading>
                      <Spacer />
                      {x.tags.map((y, i) => (
                        <Badge
                          key={i}
                          fontSize="3xs"
                          bgColor="red"
                          color="white"
                        >
                          {y}
                        </Badge>
                      ))}
                    </HStack>
                    <HStack p="1">
                      <Box color={colors.white}>
                        <Text fontSize="sm">{x.instructor}</Text>
                        <Text fontSize="sm">{x.instructor_titles}</Text>
                      </Box>

                      <Spacer />
                      <Heading
                        textAlign="end"
                        fontSize="sm"
                        color={colors.white}
                      >
                        IDR
                        {Intl.NumberFormat("en-ID", {
                          maximumSignificantDigits: 3,
                        }).format(x.price)}
                      </Heading>
                    </HStack>
                    <Button colorScheme="blue" width="full">
                      Lihat
                    </Button>
                  </Box>
                </Center>
              </Link>
            ))
          ) : (
            <Center>
              <Spinner />
            </Center>
          )}
        </SimpleGrid>
      </Box>
    </>
  );
}

export default AcademyPage;
