import React, { useState, useEffect, useContext } from "react";
import { Box, Heading, HStack, Spacer, Stack, Text } from "@chakra-ui/react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../Config/firebase";
import AuthContext from "../Routes/hooks/AuthContext";
import { orderBy } from "lodash";
import colors from "../Utils/colors";

function OrderPage() {
  const [dataOrder, setDataOrder] = useState();
  const { currentUser } = useContext(AuthContext);

  const getOrder = async () => {
    try {
      const q = query(
        collection(db, "orders"),
        where("user_uid", "==", currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      let data = [];
      querySnapshot.forEach((doc) => {
        let newData = doc.data();
        newData.id = doc.id;
        data.push(newData);
      });
      setDataOrder(data);
      console.log(dataOrder, "ini dataa order");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrder();

    return () => {};
  }, []);

  return (
    <Box color={colors.white}>
      {dataOrder ? (
        dataOrder?.map((x, i) => (
          <Box key={i} border={"1px"} m={4} borderRadius={"md"} p={3}>
            <Heading>Order : {dataOrder?.length}</Heading>
            <Stack key={i} spacing={"none"}>
              <Text>{x?.bank_code}</Text>
              <Text>Qty: {x?.products?.products?.length} items</Text>
              <Text>
                Total Price: IDR{" "}
                {Intl.NumberFormat("en-ID", {
                  maximumSignificantDigits: 3,
                }).format(x?.totalPrice)}
              </Text>
            </Stack>
          </Box>
        ))
      ) : (
        <>
          <Heading>Tidak ada di dalam order {dataOrder?.length}</Heading>
        </>
      )}
    </Box>
  );
}

export default OrderPage;
