import { Container } from "@chakra-ui/react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AcademyPage from "../Pages/AcademyPage";
import BookSinglePage from "../Pages/BookSinglePage";
import CartPage from "../Pages/CartPage";
import CourseSinglePage from "../Pages/CourseSinglePage";
import CustomProductPage from "../Pages/CustomProductPage";
import FavoritePage from "../Pages/FavoritePage";
import HomePage from "../Pages/HomePage";
import OrderPage from "../Pages/OrderPage";
import ProductPage from "../Pages/ProductPage";
import ProductPageV2 from "../Pages/ProductPageV2";
import ProductSinglePage from "../Pages/ProductSinglePage";
import ProfilePage from "../Pages/ProfilePage";
import AdminRegistration from "../Pages/registration/AdminRegistration";
import CheckoutPage from "../Pages/registration/CheckoutPage";
import LoginEmail from "../Pages/registration/LoginEmail";
import LoginPage from "../Pages/registration/LoginPage";
import SignUpPage from "../Pages/registration/SignUpPage";
import ProtectedRoutes from "./ProtectedRoutes";
import ProtectedRoutesUser from "./ProtectedRoutesUser";

function MainRoute() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />

      {/* <Route path="/product" element={<ProductPage />} /> */}
      <Route path="/product" element={<ProductPageV2 />} />
      <Route path="/product/:id" element={<ProductSinglePage />} />

      <Route path="/academy" element={<AcademyPage />} />
      <Route path="/courses/:id" element={
        <ProtectedRoutesUser>
          <CourseSinglePage />
       </ProtectedRoutesUser> 
      } />
      <Route path="/bookoftheweek/:id" element={<BookSinglePage />} />
      <Route path="/admin" element={<AdminRegistration />} />

      <Route path="/cart" element={<CartPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/order" element={<OrderPage />} />
      <Route path="/checkout" element={<CheckoutPage />} />
      <Route path="/custom" element={<CustomProductPage />} />

      <Route path="/login/email" element={<LoginEmail />} />
      <Route path="/login/phone" element={<LoginPage />} />
      <Route path="/signup" element={<SignUpPage />} />
    </Routes>
  );
}

export default MainRoute;
