import axios from "axios";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../Config/firebase";

export const midtransPayment = async (data, total, uid, bank, name) => {
  const newUrl = `https://dev-api.importir.com/api/payment/custom-create-va`;

  //BODY CREATE PAYMENT
  //dinamis bisa diganti name
  // const dataArr = {
  //   company: "EPD",
  //   bank_code: bank,
  //   name: "cloud kitchen",
  //   expected_amount: parseInt(total),
  //   uid: uid,
  //   data,
  // };

  const newBody = {
    company: "EPD",
    external_id: uid,
    bank_code: bank,
    name: name,
    expected_amount: parseInt(total),
    callback_url:
      "http://localhost:5001/brainology-id/us-central1/paymentCallback",
    payment_type: "midtrans",
  };

  console.log(newBody, "ini datanya payment.js");
  const result = await axios.post(newUrl, newBody);
  if (result.status) {
    // await setDoc(doc(db, "cart", uid), {});
    return result;
  } else return null;
};
