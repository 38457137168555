import { Box } from '@chakra-ui/react'
import React from 'react'

function FavoritePage() {
  return (
	<Box height='100vh'>

	</Box>
  )
}

export default FavoritePage