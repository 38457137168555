import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  Radio,
  RadioGroup,
  Stack,
  Flex,
  Center,
  Spinner,
  Divider,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { midtransPayment } from "../Api/payment";
import AuthContext from "../Routes/hooks/AuthContext";
import { AiOutlineCopy } from "react-icons/ai";
import {
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
  AiOutlineDelete,
} from "react-icons/ai";
import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../Config/firebase";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import store from "store";
import CopyToClipboard from "react-copy-to-clipboard";
import colors from "../Utils/colors";

function CartPage() {
  const user_data = store.get("user_data");

  const [totalPrice, setTotalPrice] = useState(0);
  const { cartList, currentUser } = useContext(AuthContext);
  const [response, setResponse] = useState("ngentot");
  const [bank, setBank] = useState();
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser == null || currentUser == undefined) {
      navigate("/login/phone");
    }
  }, []);

  const countTotal = () => {
    console.log(_.isEmpty(cartList));
    if (_.isEmpty(cartList)) {
      setTotalPrice(0);
    } else {
      let total = 0;
      cartList.products.map((x) => (total += x.price * x.qty));
      setTotalPrice(total);
    }
  };

  const handleFirebase = async (data) => {
    try {
      await setDoc(doc(db, "cart", currentUser.uid), data);
    } catch (error) {
      console.log(error.message, "errorin setdoc cart uid");
    }
  };

  const handleMinus = async (i) => {
    const data = cartList;
    if (data.products[i].qty - 1 > 0) {
      const newQty = data.products[i].qty - 1;
      data.products[i].qty = newQty;
    } else {
      data.products.splice(i, 1);
    }
    await handleFirebase(data);
  };

  const handleAdd = async (i) => {
    const data = cartList;
    const newQty = data.products[i].qty + 1;
    data.products[i].qty = newQty;
    await handleFirebase(data);
  };

  const handleDelete = async (i) => {
    const data = cartList;
    data.products.splice(i, 1);
    await handleFirebase(data);
  };
  const handlePostCart = async (id) => {
    setLoading(true);
    const checkout = {
      products: cartList,
      totalPrice: totalPrice,
      user_uid: auth.currentUser.uid,
    };
    console.log(checkout);

    try {
      const update = await addDoc(collection(db, "orders"), checkout);
      console.log(`doc written with id: ${update.id}`);
      setLoading(false);
      // console.log(cartList?.products, "ini user");
    } catch (error) {
      console.log(error, "ini error");
    }

    try {
      const userContentUpdate = await setDoc(
        doc(db, "users", currentUser.uid),
        cartList,
        {
          merge: true,
        }
      );
      console.log(`doc with id: ${currentUser.uid} is updated`);
      // console.log(userContentUpdate, "ini updatenya loh");
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
    navigate("/checkout", { state: checkout });
  };

  const handlePayment = async () => {
    setLoading(true);
    const res = await midtransPayment(
      cartList,
      totalPrice,
      currentUser.uid,
      bank,
      user_data.name
    );
    console.log(res.data, "ini di handle payment");

    setResponse(res.data);
    setLoading(false);
  };

  useEffect(() => {
    countTotal();
    return () => { };
  }, [cartList]);

  useEffect(() => {
    return setResponse(null);
  }, []);

  const width = window.innerWidth
  const height = window.innerHeight


  return (
    <Box bg={colors.darkblue} minH={height}>
      <Stack >
        <Heading
          mt={10}
          fontSize={20}
          fontWeight={500}
          textAlign="center"
          color="white"
        >
          Cart
        </Heading>

        <Box
          bg={colors.semidark}
          padding={5}
          width="md"
        // right={0}
        // left={0}
        >
          {totalPrice > 0 ? (
            <>
              {/* <RadioGroup onChange={setBank} value={bank}>
                <Stack direction="column">
                  <Radio size="sm" value="BRI">
                    BRI Virtual Account
                  </Radio>
                  <Radio size="sm" value="BNI">
                    BNI Virtual Account
                  </Radio>
                  <Radio size="sm" value="MANDIRI">
                    MANDIRI Virtual Account
                  </Radio>
                  <Radio size="sm" value="PERMATA">
                    PERMATA Virtual Account
                  </Radio>
                </Stack>
              </RadioGroup> */}
              <HStack>
                <Spacer />
                <HStack color={"white"}>
                  <Text>Total</Text>
                  <Heading size="md">
                    IDR{" "}
                    {totalPrice
                      ? Intl.NumberFormat("en-ID", {
                        maximumSignificantDigits: 3,
                      }).format(totalPrice)
                      : 0}
                  </Heading>
                </HStack>
              </HStack>
              <Divider />
              {/* {response == null ? null : (
                <Stack alignItems="center">
                  <Text fontSize={13}>
                    Type : {response?.data?.type_payment}
                  </Text>
                  <HStack>
                    <Text fontSize={13}>
                      Virtual Account {bank}:{" "}
                      <strong>{response?.data?.account_number}</strong>
                    </Text>

                    <Box
                      padding={2}
                      borderWidth={1}
                      borderColor="tomato"
                      borderRadius={5}
                      _hover={{ bg: "gray.200" }}
                      _active={{ bg: "gray.400" }}
                      cursor="pointer"
                    >
                      <CopyToClipboard
                        text={response?.data?.account_number}
                        onCopy={() => setCopied(true)}
                      >
                        <AiOutlineCopy color="tomato" />
                      </CopyToClipboard>
                    </Box>
                  </HStack>
                  {copied ? (
                    <Text fontSize={10} color="gray.600">
                      VA berhasil dicopy.
                    </Text>
                  ) : null}
                  <Text>{JSON.stringify(response)}</Text>
                </Stack>
              )} */}
            </>
          ) : (
            <></>
          )}
          <Box justifyContent="center">
            <Button
              m={5}
              w="90%"
              // onClick={() => handlePayment()}
              onClick={handlePostCart}
              alignSelf="center"
              bg={colors.theme}
            >
              Checkout {loading ? <Spinner mx={4} /> : null}
            </Button>
          </Box>
        </Box>
        <Center
          justifySelf="center"
          display="flex"
          flexDirection="column"
          overflowY="scroll"
          css={{
            "&::-webkit-scrollbar": {
              width: "0",
            },
          }}
        >
          {cartList?.products ? (
            cartList.products.map((x, i) => (
              <>
                <Box
                  key={i}
                  boxShadow="md"
                  w="95%"
                  px={3}
                  py={1}
                  bg={colors.semidark}
                  borderRadius={"md"}
                  boxRadius="md"
                  mb={2}
                >
                  <HStack>
                    <Image p="1" src={x.image} width="32" alt={x.name} />
                    <Box color={colors.white}>
                      <Text fontSize={14}>{x.name}</Text>
                      <Text fontSize={14} fontWeight={"bold"}>
                        Rp{" "}
                        {Intl.NumberFormat("en-ID", {
                          maximumSignificantDigits: 3,
                        }).format(x.price)}
                      </Text>
                    </Box>
                    <Spacer />
                    {!x.productType ? (
                      <HStack p="2">
                        <Icon
                          as={AiOutlineMinusCircle}
                          color="red"
                          cursor="pointer"
                          onClick={() => handleMinus(i)}
                        />
                        <Text color={colors.white}>{x.qty}</Text>
                        <Icon
                          as={AiOutlinePlusCircle}
                          color="white"
                          cursor="pointer"
                          onClick={() => handleAdd(i)}
                        />
                        <Icon
                          as={AiOutlineDelete}
                          color="white"
                          cursor="pointer"
                          onClick={() => handleDelete(i)}
                        />
                      </HStack>
                    ) : (
                      <Icon
                        as={AiOutlineDelete}
                        color="white"
                        cursor="pointer"
                        onClick={() => handleDelete(i)}
                      />
                    )}
                  </HStack>
                </Box>
              </>
            ))
          ) : (
            <Flex fontSize="xs" direction="column">
              <Text>Tidak ada produk di keranjang anda saat ini</Text>
              <Button alignSelf="center" colorScheme="blue">
                <Link to="/order">Lihat Order</Link>
              </Button>
            </Flex>
          )}
        </Center>

        <Spacer />
      </Stack>
    </Box>
  );
}

export default CartPage;
