import {
  Badge,
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../Routes/hooks/AuthContext";
import colors from "../Utils/colors";

const ProductPageV2 = () => {
  const { products, courses } = useContext(AuthContext);

  return (
    <Box
      py={5}
      px={5}
      overflowY={"scroll"}
      css={{
        "&::-webkit-scrollbar": {
          width: "0",
        },
      }}
    >
      <Tabs isFitted>
        <TabList mb="1em" color={colors.white}>
          <Tab _selected={{ color: colors.theme }} fontWeight={"bold"}>
            COURSES
          </Tab>
          <Tab _selected={{ color: colors.theme }} fontWeight={"bold"}>
            AUDIO BOOKS
          </Tab>
          <Tab _selected={{ color: colors.theme }} fontWeight={"bold"}>
            PRODUCTS
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <SimpleGrid columns={1} gap={2}>
              {courses?.length > 0 ? (
                courses?.map((x, i) => (
                  <Link key={i} to={`/courses/${x.id}`}>
                    <Center>
                      <Box
                        shadow="base"
                        p="2"
                        m="1"
                        boxShadow="inner"
                        width="sm"
                        borderRadius={"md"}
                        bg={colors.semidark}
                      >
                        <Center>
                          <Image
                            src={x.thumbnail}
                            width="full"
                            padding="2"
                            borderRadius="md"
                          />
                        </Center>
                        <HStack p="1">
                          <Heading fontSize="md" color={colors.white}>
                            {x.name}
                          </Heading>
                          <Spacer />
                          {x.tags.map((y, i) => (
                            <Badge
                              key={i}
                              fontSize="3xs"
                              bgColor="red"
                              color="white"
                            >
                              {y}
                            </Badge>
                          ))}
                        </HStack>
                        <HStack p="1">
                          <Box color={colors.white}>
                            <Text fontSize="sm">{x.instructor}</Text>
                            <Text fontSize="sm">{x.instructor_titles}</Text>
                          </Box>

                          <Spacer />
                          <Heading
                            textAlign="end"
                            fontSize="sm"
                            color={colors.white}
                          >
                            IDR
                            {Intl.NumberFormat("en-ID", {
                              maximumSignificantDigits: 3,
                            }).format(x.price)}
                          </Heading>
                        </HStack>
                        <Button bg={colors.theme} width="full">
                          Lihat
                        </Button>
                      </Box>
                    </Center>
                  </Link>
                ))
              ) : (
                <Center>
                  <Spinner />
                </Center>
              )}
            </SimpleGrid>
          </TabPanel>
          <TabPanel>
            <SimpleGrid columns={1} gap={2}>
              {/* {data ? data.map((item, index)=>(
                <Box m={1} display='relative' bg='white' height={width /1.4} borderRadius={5} >
                  <Box top={0} right={0} left={0} >
                    <Image width={width/2.2} height={width/2.2} source={{ uri: item.image }} alt={item.name} />
                  </Box>
                  <Box position='fixed'bottom={0} right={0} left={0} height='50%'>
                    <Text fontSize={12} fontWeight='600'>{item.name}</Text>
                    <Text fontSize={10}>{item.description}</Text>
                    <Text style={{ fontWeight: 'bold' }}>Rp {item.price}</Text>
                    <Text fontSize={10}>Stock: 12</Text>
                  </Box>
                </Box>
              )) : null} */}
                <Center>
                  <Spinner />
                </Center>
            
            </SimpleGrid>
          </TabPanel>
          <TabPanel>
            {products?.length > 0 ? (
              products?.map((x, i) => (
                <Link key={i} to={`/product/${x.id}`}>
                  <Center>
                    <Box
                      shadow="base"
                      p="2"
                      m="1"
                      boxShadow="inner"
                      width="sm"
                      borderRadius={"md"}
                      bg={colors.semidark}
                    >
                      <Center>
                        <Image
                          src={x.thumbnail}
                          width="full"
                          padding="2"
                          borderRadius="md"
                        />
                      </Center>
                      <HStack p="1">
                        <Heading fontSize="md" color={colors.white}>
                          {x.nama}
                        </Heading>
                        <Spacer />
                        {x.tags.map((y, i) => (
                          <Badge
                            key={i}
                            fontSize="3xs"
                            bgColor="red"
                            color="white"
                          >
                            {y}
                          </Badge>
                        ))}
                      </HStack>
                      <HStack p="1">
                        <Box>
                          {/* <Text fontSize='sm'>Instructor{x.teacher}</Text> */}
                          <Text fontSize="sm" color={colors.white}>
                            {x.subtitle}
                          </Text>
                        </Box>

                        <Spacer />
                        <Heading
                          color={colors.white}
                          textAlign="end"
                          fontSize="sm"
                        >
                          IDR
                          {Intl.NumberFormat("en-ID", {
                            maximumSignificantDigits: 3,
                          }).format(x.price)}
                        </Heading>
                      </HStack>
                      <Button bg={colors.theme} width="full">
                        Lihat
                      </Button>
                    </Box>
                  </Center>
                </Link>
              ))
            ) : (
              <Center>
                <Spinner />
              </Center>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ProductPageV2;
